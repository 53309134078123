import React, { useState, useEffect } from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { Link, navigate } from "gatsby";
import { useLocation } from "@reach/router";

import { CHATFLOW_ID, EXPERTISES_SUBMENU, SOLUTIONS_SUBMENU } from "../constants/constants";

import currentCommitHash from "../current-commit-hash";

import Cookies from "../components/Cookies";

import "../css/Header.css";

const HeaderSubMenuItem = props => {
  return (
    <Link className="submenu-item" to={props.link}>
      {props.label}
    </Link>
  );
};

/**
 * Menu for browser version
 */
const HeaderBrowser = props => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const prefixURL = props.prefixURL ?? "";

  return (
    <div className="zr__header abel cf dn db-l sticky bg-white z-max">
      <div className="logo-container fl w-100 w-20-l flex items-center">
        <div className="w-90 w-100-l pa1 tc-l">
          <Link to={prefixURL === "" ? "/" : prefixURL + "/"} className="header-menu-logo-a">
            <img className="header-menu-logo" src={"/logo_header.svg"} alt="Logo" />
          </Link>
        </div>
      </div>

      <div className={`fl w-100 w-80-l dn db-l tc`}>
        <nav>
          <Link className="menu-item" to={`${prefixURL}/special-freight/`}>
            {t("nav.transpexcep")}
          </Link>
          <div className="dropdown">
            <HeaderMenuDropdown
              title={t("nav.solutions.title")}
              submenu={SOLUTIONS_SUBMENU}
              prefixURL={props.prefixURL}
            />
          </div>
          <div className="dropdown">
            <HeaderMenuDropdown
              title={t("nav.expert.title")}
              submenu={EXPERTISES_SUBMENU}
              prefixURL={props.prefixURL}
            />
          </div>
          <Link className="menu-item" to={`${prefixURL}/about-us/`}>
            {t("nav.aboutus")}
          </Link>
          <a href="https://blog.ziegler-sr.com" className="menu-item" target="_blank" rel="noreferrer">
            {t("nav.blog")}
          </a>
          <div className="flex items-center ph2">
            <div className="menu-separator"></div>
          </div>
          <div
            className="menu-language flex items-center ph2 pointer"
            onClick={props.changeLanguage}
            role="button"
            tabIndex="0"
          >
            <div className="mr1">
              <img src="/icons/earth.svg" alt="earth" />
            </div>
            <div style={{ height: "22px" }}>{language === "fr" ? "English" : "Français"}</div>
          </div>
        </nav>
      </div>
    </div>
  );
};

/**
 * Menu dropdow
 * @param string title : Text display
 * @param string id : unique id for submenu mobile
 * @param {function} openSubMenu
 * @returns
 */
const HeaderMenuDropdown = props => {
  const { t } = useTranslation();
  const prefixURL = props.prefixURL ?? "";
  const isMobileMenu = props.isMobileMenu ?? false;

  const openSubMenuMobile = () => {
    if (props.openSubMenu === undefined) return;
    props.openSubMenu(props.id);
  };

  let nameArrowIcon = "menu-arrow-down.svg";
  if (isMobileMenu)
    nameArrowIcon = props.idOpen === props.id ? "menu-mobile-arrow-up.svg" : "menu-mobile-arrow-down.svg";

  let subMenuJSX = "";
  if (!isMobileMenu || props.idOpen === props.id) {
    subMenuJSX = (
      <div className={`dropdown-content`}>
        {props.submenu.map(expertise => {
          const menuLink = prefixURL + expertise.link;
          return <HeaderSubMenuItem key={expertise.code} link={menuLink} label={t(expertise.translate)} />;
        })}
      </div>
    );
  }

  return (
    <>
      <div className="menu-item" onClick={openSubMenuMobile} role="button" tabIndex="0">
        <div>{props.title}</div>
        <div>
          <img src={`/icons/${nameArrowIcon}`} alt="" className="ml1" />
        </div>
      </div>
      {subMenuJSX}
    </>
  );
};

/**
 * Menu for mobile version
 */
const HeaderMobile = props => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const prefixURL = props.prefixURL ?? "";

  const [isOpen, setIsOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState("");

  useEffect(() => {
    return () => {
      document.body.style.position = "";
    };
  }, []);

  // Show or Hide the mobile menu
  const showHideMobileMenuHandler = () => {
    setIsOpen(isOpenBefore => {
      const newIsOpen = !isOpenBefore;
      if (false === newIsOpen) setSubmenuOpen("");
      document.body.style.position = newIsOpen ? "fixed" : "";
      return newIsOpen;
    });
  };

  // Show or Hide submenu for mobile version
  const openSubMenuHandler = id => {
    setSubmenuOpen(idBefore => {
      if (id === idBefore) return "";
      return id;
    });
  };

  const menuMobileJSX = (
    <nav className={`pt5 overflow-auto`}>
      <Link className="menu-item" to={`${prefixURL}/special-freight/`}>
        {t("nav.transpexcep")}
      </Link>
      <HeaderMenuDropdown
        title={t("nav.solutions.title")}
        openSubMenu={openSubMenuHandler}
        id="solutions"
        submenu={SOLUTIONS_SUBMENU}
        prefixURL={props.prefixURL}
        idOpen={submenuOpen}
        isMobileMenu={true}
      />
      <HeaderMenuDropdown
        title={t("nav.expert.title")}
        openSubMenu={openSubMenuHandler}
        id="expert"
        submenu={EXPERTISES_SUBMENU}
        prefixURL={props.prefixURL}
        idOpen={submenuOpen}
        isMobileMenu={true}
      />
      <Link className="menu-item" to={`${prefixURL}/about-us/`}>
        {t("nav.aboutus")}
      </Link>
      <a href="https://blog.ziegler-sr.com" className="menu-item" target="_blank" rel="noreferrer">
        {t("nav.blog")}
      </a>
      <div
        className="menu-language flex items-center ph2 pointer mt5"
        onClick={props.changeLanguage}
        role="button"
        tabIndex="0"
        style={{ height: "56px" }}
      >
        <div>
          <img src="/icons/earth.svg" alt="earth" className="mr1" />
        </div>
        <div style={{ height: "22px" }}>{language === "fr" ? "English" : "Français"}</div>
      </div>
    </nav>
  );

  return (
    <div className={`dn-l bg-white zr__header abel w-100 cf ${isOpen ? "aspect-ratio--object fixed" : "sticky z-max"}`}>
      <div className="logo-container fl w-100 flex items-center">
        <div className="w-90 pa1">
          <Link to={prefixURL === "" ? "/" : prefixURL + "/"} className="header-menu-logo-a">
            <img className="header-menu-logo" src={"/logo_header.svg"} alt="Logo" />
          </Link>
        </div>
        <div className="w-10 pr2" onClick={showHideMobileMenuHandler} role="button" tabIndex="0">
          {!isOpen && <img src="/icons/bars.svg" alt="menu" />}
          {isOpen && <img src="/icons/menu-close.svg" alt="close" />}
        </div>
      </div>

      {isOpen && menuMobileJSX}
    </div>
  );
};

/**
 * Display the header with menu
 * @returns
 */
const Header = () => {
  // const { isPortrait } = useMobileOrientation();
  const { language } = useI18next();
  const location = useLocation();
  const prefixURL = "fr" !== language ? `/${language}` : "";

  // Change the language page
  const onChangeLanguageHandler = () => {
    var pathname = location.pathname;
    if (pathname.indexOf("/en/") === 0) {
      pathname = pathname.substring(3);
    }
    const langToChange = language === "fr" ? "en" : "";
    const prefix = langToChange !== "" ? `/${langToChange}` : "";
    navigate(prefix + pathname);
  };

  return (
    <>
      <HeaderBrowser changeLanguage={onChangeLanguageHandler} prefixURL={prefixURL} />
      <HeaderMobile changeLanguage={onChangeLanguageHandler} prefixURL={prefixURL} />
      <div className="dn" id="currentCommitHash">
        {currentCommitHash}
      </div>
      <Cookies />
    </>
  );
};

export default Header;
