import { Link } from "gatsby";
import React, { useState, useLayoutEffect } from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { COOKIES_KEY } from "../constants/constants";

import "../css/Cookies.css";
import CookiesSettings from "./CookiesSettings";

/**
 * Banner at the bottom of the site for the request of acceptance of the cookies
 */
const Cookies = () => {
  const cookiesSettings = { statistical: false };

  const { t } = useTranslation();
  const { language } = useI18next();

  const [cookiesAccepted, setCookiesAccepted] = useState(null); // Info cookie
  const [cookiesSettingsEdit, setCookiesSettingsEdit] = useState(cookiesSettings); // Settings cookie in the process of being modified

  const [showSettings, setShowSettings] = useState(false); // Show or Hide the settings modal

  useLayoutEffect(() => {
    let cookiesAcceptance = localStorage.getItem(COOKIES_KEY) || false;
    if (false !== cookiesAcceptance) {
      cookiesAcceptance = JSON.parse(cookiesAcceptance);
      setCookiesAccepted(cookiesAcceptance);
      setCookiesSettingsEdit(cookiesAcceptance);
    }
  }, []);

  if (cookiesAccepted !== null) {
    return null; // If the cookie exists then the choice has already been made
  }

  const rejectAllHandler = () => {
    cookiesSettings.statistical = false;
    localStorage.setItem(COOKIES_KEY, JSON.stringify(cookiesSettings));
    setCookiesAccepted(cookiesSettings);
  };

  const acceptAllHandler = () => {
    cookiesSettings.statistical = true;
    localStorage.setItem(COOKIES_KEY, JSON.stringify(cookiesSettings));
    setCookiesAccepted(cookiesSettings);
  };

  const openSettingsPage = () => {
    setShowSettings(true);
  };

  const closeSettingsPage = () => {
    setShowSettings(false);
  };

  const switchStatisticalHandler = () => {
    setCookiesSettingsEdit(cookiesSettingBefore => {
      var cookiesSettingEdit = { ...cookiesSettingBefore };
      cookiesSettingEdit.statistical = !cookiesSettingEdit.statistical;
      return cookiesSettingEdit;
    });
  };

  const saveApplyHandler = () => {
    localStorage.setItem(COOKIES_KEY, JSON.stringify(cookiesSettingsEdit));
    setCookiesAccepted(cookiesSettingsEdit);
  };

  if (showSettings) {
    return (
      <CookiesSettings
        closeSettingsPage={closeSettingsPage}
        saveApplyHandler={saveApplyHandler}
        switchStatisticalHandler={switchStatisticalHandler}
        cookiesSettingsEdit={cookiesSettingsEdit}
      />
    );
  }

  return (
    <div className="light-bg flex-l justify-center items-center pa4 w-100 fixed z-max bottom-0 cookie-box">
      <div className="fl w-100 w-60-l mb4 mb0-l">
        <div className="cookie-title">{t("cookie.title")}</div>
        <div className="cookie-text">
          {t("cookie.text")}{" "}
          <Link to={`${language !== "fr" ? "/en" : ""}/policy/cookies/`}>{'"' + t("cookie.text2") + '"'}</Link>
        </div>
      </div>

      <div className="fl w-100 w-40-l flex flex-column flex-row-l items-center justify-center">
        <button onClick={openSettingsPage} className="ph4 pointer dim pv3 b mt3 mt0-l bn bg-white black">
          {t("cookie.button1")}
        </button>
        <button onClick={rejectAllHandler} className="ph4 pointer dim pv3 b ml4-l mt3 mt0-l ba b--black black bg-white">
          {t("cookie.button2")}
        </button>
        <button onClick={acceptAllHandler} className="ph4 pointer dim pv3 b ml4-l mt3 mt0-l bn bg-black white">
          {t("cookie.button3")}
        </button>
      </div>
    </div>
  );
};

export default Cookies;
