import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

/**
 * Modal for managing cookie settings
 * @param {function} closeSettingsPage - Handler close button
 * @param {function} switchStatisticalHandler - Update the settings in progress (not saved)
 * @param {function} saveApplyHandler - Handler Save the settings
 * @param {object} cookiesSettingsEdit - Settings cookies object in progress ( not saved )
 */
const CookiesSettings = props => {
  const { t } = useTranslation();

  return (
    <div className="w-100 h-100 fixed absolute--fill z-max flex justify-center items-center bg-black-10 abel cookie-modal">
      <div className="pa3 pa4-l bg-white w-90 container">
        <div className="flex flex-row items-center justify-between">
          <div className="cookie-modal-title">{t("cookie.modal.title")}</div>
          <button className="fr cookie-modal-close bg-white bn pointer" onClick={props.closeSettingsPage}>
            <img src="/icons/menu-close.svg" alt="close" />
          </button>
        </div>
        <div className="cf flex flex-column flex-row-l items-center mt2">
          <div className="fl w-100 w-80-l pr3">
            <div className="cookie-modal-subtitle">{t("cookie.modal.s1-title")}</div>
            <p>{t("cookie.modal.s1-text")}</p>
          </div>
          <div className="fl w-100 w-20-l ph2 tr action">{t("cookie.modal.s1-active")}</div>
        </div>

        <div className="cf flex flex-column flex-row-l items-center mt3">
          <div className="fl w-100 w-80-l pr3">
            <div className="cookie-modal-subtitle">{t("cookie.modal.s2-title")}</div>
            <p>{t("cookie.modal.s2-text")}</p>
          </div>
          <div className="fl w-100 w-20-l ph2 tr">
            <img
              src={props.cookiesSettingsEdit.statistical ? "/icons/switch-on.png" : "/icons/switch-off.png"}
              className="pointer"
              onClick={props.switchStatisticalHandler}
              alt="switch"
            />
          </div>
        </div>

        <div className="tc mt3">
          <button className="bg-black white b pa3 bn pointer" onClick={props.saveApplyHandler}>
            {t("cookie.modal.apply")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiesSettings;
