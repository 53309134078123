import React from "react";
import { useTranslation } from "react-i18next";
import WrapperLink from "./ui/WrapperLink";
import { Link } from "gatsby";

const FooterItems = props => {
  return (
    <div className={`w-100 w-20-l items items-center justify-center-ns ph3 ph0-ns pv2 ${props.className ?? ""}`}>
      {props.children}
    </div>
  );
};

const FooterLinkExternal = props => {
  const forMobile = props.forMobile ?? false;
  const className = props.className ?? "";

  return (
    <>
      <FooterItems className={`${forMobile ? "justify-end" : ""} ${className}`}>
        <WrapperLink
          href="https://www.linkedin.com/company/ziegler-special-request/"
          className="white link"
          target="_blank"
        >
          <img src="/icons/linkedin.svg" alt="Linkedin" />
        </WrapperLink>
      </FooterItems>
      <FooterItems className={`${forMobile ? "justify-end" : ""} ${className}`}>
        <WrapperLink
          href="https://www.youtube.com/playlist?list=PLFnmwQsfKBUTfQwzy3SFrt-ZIuTjqgbVn"
          className="white link"
          target="_blank"
        >
          <img src="/icons/youtube.svg" alt="Yotube" />
        </WrapperLink>
      </FooterItems>
    </>
  );
};

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-black footer abel flex items-end items-center-l pt6 pb4 pv0-l">
      <div className="fl w-50 w-100-l white flex flex-wrap">
        <FooterItems className="flex">
          <Link to="/contact/" className="white link">
            {t("nav.footer.contact")}
          </Link>
        </FooterItems>
        <FooterItems className="flex">
          <WrapperLink href="https://www.tzg-infocenter.com/gdpr/ziegler/" className="white link" target="_blank">
            {t("nav.footer.privacy")}
          </WrapperLink>
        </FooterItems>
        <FooterItems className="flex">
          <WrapperLink href="https://www.zieglergroup.com" className="white link" target="_blank">
            Ziegler Group
          </WrapperLink>
        </FooterItems>
        <FooterLinkExternal className="dn flex-l" />
      </div>
      <div className="fl w-50 dn-l white flex flex-wrap justify-end">
        <FooterLinkExternal forMobile={true} className="flex" />
      </div>
    </div>
  );
};

export default Footer;
