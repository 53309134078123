import React from "react";

/**
 * Allows you to add an <a> tag if the link exists otherwise a simple <span>
 * @param string href
 * @param string [target] - _blank, _self, ...
 * @param string [className] - Class to be added
 * @returns
 */
const WrapperLink = props => {
  const target = props.target ?? "_self";
  const rel = "_blank" === target ? "noopener noreferrer" : "";

  if (props.href) {
    return (
      <a href={props.href} className={props.className} target={target} rel={rel}>
        {props.children}
      </a>
    );
  }
  return <span className={props.className}>{props.children}</span>;
};

export default WrapperLink;
