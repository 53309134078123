// Usage : import { CHATFLOW_ID, COOKIES_KEY } from "../constants/constants";
export const CHATFLOW_ID = "7525577";
export const COOKIES_KEY = "ziegler-labs-special-request";

export const SOLUTIONS_SUBMENU = [
  { code: "transp", link: "/solutions/freight/", translate: "nav.solutions.transport" },
  { code: "cust", link: "/solutions/customs/", translate: "nav.solutions.customs" },
  { code: "tracki", link: "/solutions/tracking/", translate: "nav.solutions.tracking" }
];

export const EXPERTISES_SUBMENU = [
  { code: "air", link: "/expertises/aerospace/", translate: "nav.expert.air" },
  { code: "auto", link: "/expertises/automotive/", translate: "nav.expert.auto" },
  { code: "manufact", link: "/expertises/manufacturing/", translate: "nav.expert.industry" },
  { code: "security", link: "/expertises/defence-security/", translate: "nav.expert.security" },
  { code: "art", link: "/expertises/event-art/", translate: "nav.expert.art" },
  { code: "btp", link: "/expertises/construction/", translate: "nav.expert.btp" },
  { code: "luxe", link: "/expertises/luxury/", translate: "nav.expert.luxe" },
  { code: "sea", link: "/expertises/shipbuilding/", translate: "nav.expert.sea" },
  { code: "animal", link: "/expertises/animal/", translate: "nav.expert.animal" },
  { code: "carcoll", link: "/expertises/classic-vehicles/", translate: "nav.expert.carcoll" }
];
